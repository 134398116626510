import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import './generalDashboard.css';

//FUNCTIONS
import AppTransition from '../components/AppTransition';
import { collection, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Adjust the import path as needed

//IMPORT UI
import Sidebar from './Sidebar';
import CloseSidebar from './CloseSidebar';

//IMAGES
import creatoricon from '../assets/images/v2-creator.png';
import guideImage from '../assets/images/new-plan-feature.png';
import langSettings from '../assets/images/lang-swtich.png';
import brandLogo from '../assets/images/process-v1.png';
import timeLogo from '../assets/images/clock-v2.png';
import newUserachievement from '../assets/images/newuser.jpg';
import noAccessImageActive from '../assets/images/thumbnailPlacehlder-active.jpg';



function GeneralDashboard({ isSidebarOpen, toggleSidebar }) {




    return (
        <div className="dashboard">
        {isSidebarOpen && <Sidebar />}
        {!isSidebarOpen && <CloseSidebar />}
        <AppTransition>
          <div className="dashboard-main">
            <div className="dashboard-content-wrapper">
              <div className="dashboard-contentgeneral">
                {/* <VideoStats /> */}
                <Guides /> 
                {/* <RecentVideos /> */}
              </div>
              <div className="dashboard-right">
                <Achievement />
                <Suggestions />
                <RecentBump />
              </div>
            </div>
          </div>
          </AppTransition>
        </div>
        
      );
      
      
}


function Achievement() {
  const achievement = {
    title: "New Bumper!",
    description: "Congrats • Looks like you have joined the bumpups platform"
  };

  // State for controlling the popup
  const [isTrailerPopupOpen, setIsTrailerPopupOpen] = useState(false);

  // Function to open the trailer popup
  const openTrailerPopup = () => {
    setIsTrailerPopupOpen(true);
  };

  // Function to close the trailer popup by clicking on the overlay
  const closeTrailerPopup = (e) => {
    if (e.target.className === 'trailer-popup-overlay') {
      setIsTrailerPopupOpen(false);
    }
  };

  // Disable scroll when popup is open
  useEffect(() => {
    if (isTrailerPopupOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto'; // Clean up to restore scroll
    };
  }, [isTrailerPopupOpen]);

  return (
    <div className="achievement-box">
      <h3>New achievement</h3>
      <div className="achievement-content">
        <img src={newUserachievement} alt="Achievement" className="achievement-icon" />
        <div>
          <p className="achievement-title"><strong>{achievement.title}</strong></p>
          <p className="achievement-description">{achievement.description}</p>
        </div>
      </div>
      <button className="view1mindemo-btn" onClick={openTrailerPopup}>VIEW 1 MIN DEMO</button>

      {/* Trailer Popup Logic */}
      {isTrailerPopupOpen && (
        <div className="trailer-popup-overlay" onClick={closeTrailerPopup}>
          <div className="trailer-popup-content">
            <video controls autoPlay className="trailer-video-player">
              <source
                src="https://firebasestorage.googleapis.com/v0/b/bumpups.appspot.com/o/bump-trailer%2FBumpups%20in%2060%20seconds.mp4?alt=media&token=1e4a10fe-75e0-4e4f-bbe7-4710d61a4f61"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </div>
  );
}
  
  const Suggestions = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 3; // Assuming there are 3 pages
  
    // Updated content for different pages with associated links
    const pageContents = [
      {
        title: "Bump AI variables",
        description: "Use variables like {{VIDEO_DESCRIPTION}} for Bump AI content.",
        link: "https://intercom.help/bumpups/en/articles/9742235-what-are-the-bump-ai-variables",
        image: brandLogo
      },
      {
        title: "Connecting your youtube channel",
        description: "Your videos will automatically display, simplifying management.",
        link: "https://intercom.help/bumpups/en/articles/8859075-how-do-i-connect-my-youtube-channel", // Replace with actual URL
        image: creatoricon
      },
      {
        title: "Your processing time",
        description: "Processing time on Bumpups is deducted per video, with beneficial rounding up.",
        link: "https://intercom.help/bumpups/en/articles/8858577-how-are-credits-deducted", // Replace with actual URL
        image: timeLogo
      },
    ];
  
    const nextPage = () => {
      setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    };
  
    const prevPage = () => {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };
  
    const openLink = () => {
      const { link } = pageContents[currentPage - 1];
      window.open(link, '_blank');
    };
  
    // Get the content for the current page
    const { title, description, image } = pageContents[currentPage - 1];
  
    return (
      <div className="suggestions-container">
      <div className="suggestions-card">
        <div className="suggestions-content">
          <h3>Suggestions for you</h3>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="suggestions-icon">
          <img src={image} alt={title} /> {/* Use dynamic image and alt text */}
        </div>
      </div>
        <div className="suggestions-actions">
          <button className="suggestions-start-btn" onClick={openLink}>READ NOW</button>
          <div className="suggestions-pagination">
            <span className="pagination-arrowbox" onClick={prevPage}>&lt;</span>
            {`${currentPage}/${totalPages}`}
            <span className="pagination-arrowbox" onClick={nextPage}>&gt;</span>
          </div>
        </div>
      </div>
    );
  };





  function RecentBump() {
    const [recentVideo, setRecentVideo] = useState(null);
    const navigate = useNavigate();
  
    const checkFirestore = useCallback((userId) => {
      // console.log("Checking Firestore for recent bump...");
  
      const userVideosRef = collection(db, "users", userId, "bumpContent");
  
      return onSnapshot(userVideosRef, (snapshot) => {
        if (!snapshot.empty) {
          const videosFromFirestore = [];
          snapshot.forEach((doc) => {
            const videoData = doc.data();
            videoData.id = doc.id.replace('bump-', ''); // Remove the 'bump-' prefix
            videosFromFirestore.push(videoData);
          });
  
          // Sort videos by video_upload_time in descending order and take the most recent one
          videosFromFirestore.sort((a, b) => b.video_upload_time.seconds - a.video_upload_time.seconds);
          const mostRecentVideo = videosFromFirestore[0];
          setRecentVideo(mostRecentVideo);
  
          // console.log("Recent bump fetched from Firestore:", mostRecentVideo);
  
          // Store the most recent video in local storage
          localStorage.setItem(`recentBump_${userId}`, JSON.stringify(mostRecentVideo));
        } else {
          setRecentVideo(null);
          // console.log("No videos found in Firestore, storing noBumps flag.");
          // Store a flag indicating no recent bump
          localStorage.setItem(`recentBump_${userId}`, JSON.stringify({ noBumps: true }));
        }
      }, (error) => {
        // console.error("Error fetching data from Firestore:", error);
      });
    }, []);
  
    useEffect(() => {
      const checkDataAvailability = async () => {
        const user = auth.currentUser;
  
        if (user) {
          // Check local storage first
          const localStorageData = localStorage.getItem(`recentBump_${user.uid}`);
          if (localStorageData) {
            const parsedData = JSON.parse(localStorageData);
            if (parsedData.noBumps) {
              // console.log("No recent bump found in local storage (noBumps flag).");
              setRecentVideo(null);
            } else {
              // console.log("Recent bump fetched from local storage:", parsedData);
              setRecentVideo(parsedData);
            }
          } else {
            // console.log("No data found in local storage, checking Firestore...");
            checkFirestore(user.uid);
          }
        }
      };
  
      checkDataAvailability();
    }, [checkFirestore]);
  
    const truncateText = (text, maxLength) => {
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };
  
    const formatDuration = (seconds) => {
      if (!seconds) return "";
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      
      const hoursText = hours > 0 ? `${hours}h ` : "";
      const minutesText = minutes > 0 ? `${minutes}m ` : "";
      const secondsText = `${secs}s`;
    
      return `${hoursText}${minutesText}${secondsText}`.trim();
    };
  
    const handleVideoClick = (videoId) => {
      // console.log("Video ID:", videoId); // Console log to debug the issue
      navigate(`/bumper/${videoId}`);
    };
  
    const handleLearnMoreClick = () => {
      window.open('https://intercom.help/bumpups/en/articles/9650593-how-do-i-publish-my-video-with-bump-ai', '_blank');
    };
  
    return (
      <div className="recent-bump-box">
        <h3>Recent Bump</h3>
        {recentVideo ? (
          <div className="recent-bump-content">
            <div onClick={() => handleVideoClick(recentVideo.id)}>
              <img
                src={recentVideo.video_thumbnail || noAccessImageActive}
                alt="Thumbnail"
                className="recent-bump-thumbnail"
              />
            </div>
            <div className="recent-bump-info-container" onClick={() => handleVideoClick(recentVideo.id)}>
              <p className="recent-bump-title">
                <strong>{truncateText(recentVideo.publish_title || recentVideo.video_file_name, 12)}</strong>
              </p>
              <div className="recent-bump-info">
                <p className="recent-bump-duration">{formatDuration(recentVideo.video_duration_in_seconds)}</p>
                <p className="recent-bump-meta">[{recentVideo.video_quality}]</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-recent-bump">
            Looks like you haven't bumped any videos yet! Get started with our Bump plan to boost your content.
            <button className="guides-action-btn" onClick={handleLearnMoreClick}>VIEW BUMP PLAN</button>
          </div>
        )}
      </div>
    );
  }
  
  
  
  
  
  

  const Guides = () => {

    const navigate = useNavigate(); // Initialize useNavigate hook


    const handleLearnMoreClick = () => {
      // Navigate to the internal /plans route in the same tab
      navigate('/plans');
    };

    return (
      
          <div className="guides-wrapper">
          <div className="guides-container">
            <h3 className="guides-title">New Update</h3>
            <div className="guides-thumbnail">
              <img src={guideImage} alt="Guide Thumbnail" />
            </div>
            <div className="guides-content">
              <h2>One Time Purchase Feature</h2>
              <p>The one-time purchase option allows you to buy upload minutes without committing to a plan.</p>
              {/* <button className="guides-action-btn">WATCH ON YOUTUBE</button> */}
              <button className="guides-action-btn" onClick={handleLearnMoreClick}>VIEW FEATURE</button>
            </div>
          </div>

          <div className="guides-container">
            {/* <h3 className="guides-title">New update</h3> */}
            <div className="language-settingdash">
              <img src={langSettings} alt="Guide Thumbnail" />
            </div>
            <div className="guides-content">
              <h2>Set Your Preferred Language</h2>
              <p>Choose your language to customize how video outputs like timestamps and descriptions are displayed.</p>
              <Link to="/settings" className="guides-action-btn no-underline">
                CHANGE LANGUAGE
              </Link>        
              </div>
          </div>

        </div>
    );
  };



//   function RecentVideos() {
//     // Helper function to truncate title
//     const truncateTitle = (title, maxLength) => {
//         return title.length > maxLength ? title.substring(0, maxLength) + "..." : title;
//     };

//     // Placeholder for recent videos data
//     const recentVideosData = [
//         // This should be fetched or passed as a prop
//         { title: 'ChatGPT Diagrams: Show Me GPT Tutorial - Create Flow-Chart...', thumbnail: exampleThumbnail, duration: '30m' },
//         { title: 'ChatGPT Diagrams: Show Me GPT Tutorial - Create Flow-Chart...', thumbnail: exampleThumbnail, duration: '22m' },
//         { title: 'ChatGPT Diagrams: Show Me GPT Tutorial - Create Flow-Chart...', thumbnail: exampleThumbnail, duration: '112m' },
//         // ... add more video objects here
//     ];

//     return (
//         <div className="recent-videos-wrapper">
//             <div className="recent-videos-container">
//                 <h2 className="recent-videos-header">Recent Videos Processed</h2>
//                 <div className="recent-videos-list">
//                     {recentVideosData.map((video, index) => (
//                         <div key={index} className="recent-video-item">
//                             <img src={video.thumbnail} alt="Video thumbnail" className="recent-video-thumbnail" />
//                             <div className="recent-video-info">
//                                 <span className="recent-video-title">{truncateTitle(video.title, 35)}</span>
//                                 <div className="recent-video-details">
//                                     <img src={creatoricon} alt="Creator icon" className="recent-video-creator-icon" />
//                                     <span className="recent-video-duration">{video.duration}</span>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// }




export default GeneralDashboard;