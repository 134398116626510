// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth } from './firebase'; // Ensure correct path
import { onAuthStateChanged } from 'firebase/auth';

// Create the context
export const AuthContext = createContext();

// Create the provider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        const metadata = user.metadata;
        const isNewUser = metadata.creationTime === metadata.lastSignInTime;

        if (isNewUser) {
          // Determine sign-up method
          let method = 'email'; // default
          if (user.providerData.length > 0) {
            const providerId = user.providerData[0].providerId;
            if (providerId.includes('google')) {
              method = 'google';
            } else {
              // Handle other providers if needed
              method = providerId;
            }
          }

          // Get gclid from localStorage
          const gclid = localStorage.getItem('gclid') || '';

          // Send GA4 sign_up event
          sendSignUpEvent(user.uid, gclid, method);
        } else {
          // Log returning user
          // console.log(`Returning user: ${user.uid}`);
        }
      } else {
        setCurrentUser(null);
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  const sendSignUpEvent = (userId, gclid, method = 'email') => {
    if (window.gtag) {
      window.gtag('event', 'sign_up', {
        method: method,   // e.g., 'email', 'google'
        gclid: gclid,     // Include gclid if available
        user_id: userId,  // Optional: Send user ID
      });
      // console.log(`GA4 sign_up event sent for user ${userId} with gclid: "${gclid}" and method: "${method}"`);
    } else {
      // console.error('gtag is not defined');
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
