// src/components/PaidUserPlanPopUp.js
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './PlanPopUp.css';

// FIREBASE
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, auth } from '../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";

// IMAGES
import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';

const formatTime = (minutes) => {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  return `${h}h ${m}m`;
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// Define pricing details for each plan
const pricingDetails = {
  Base: {
    originalPer100: 2.50,
    discountedPer100: 1.99,
    logo: basePlanLogo,
    color: '#ffbf00', // Color for Base plan
  },
  Bump: {
    originalPer100: 10.00,
    discountedPer100: 7.99,
    logo: bumpPlanLogo,
    color: '#4add80', // Color for Bump plan
  },
  // Add more plans here if needed
};

const PaidUserPlanPopUp = ({ isOpen, onClose, currentPlan }) => {
  const [selectedPlan, setSelectedPlan] = useState(
    currentPlan === 'Essential Plan' ? 'Base' : capitalize(currentPlan) || 'Base'
  ); // Initialize with capitalized currentPlan, treat Essential Plan as Base
  const [selectedMinutes, setSelectedMinutes] = useState(100);
  const [timeBank, setTimeBank] = useState(null);
  const [stripeButtonText, setStripeButtonText] = useState("Purchase Credits");

  // State variables for handling loading, success, and confirmation states
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  // Reference to store the confirmation timeout
  const confirmTimeoutRef = useRef(null);

  // Handle changes in the minutes selection
  const handleMinutesChange = (e) => {
    setSelectedMinutes(parseInt(e.target.value, 10));
  };

  // Calculate original and discounted prices based on the selected plan and minutes
  const calculatePrice = () => {
    const plan = pricingDetails[selectedPlan];
    if (!plan) {
      // Fallback in case the plan is not found
      return { originalPrice: '0.00', discountedPrice: '0.00' };
    }

    const { originalPer100, discountedPer100 } = plan;
    const totalOriginal = (originalPer100 * (selectedMinutes / 100)).toFixed(2);
    const totalDiscounted = (discountedPer100 * (selectedMinutes / 100)).toFixed(2);

    return { originalPrice: totalOriginal, discountedPrice: totalDiscounted };
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const subscriptionDocRef = doc(db, 'users', user.uid, 'subscriptionData', 'timeWarden');

      const fetchTimeData = async () => {
        const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
        if (sessionTimeBank !== null) {
          setTimeBank(parseInt(sessionTimeBank, 10));
        } else {
          const docSnap = await getDoc(subscriptionDocRef);
          if (docSnap.exists()) {
            const timeBankData = docSnap.data().timeBank || 0;
            setTimeBank(timeBankData);
            sessionStorage.setItem(`timeBank_${user.uid}`, timeBankData.toString());
          }
        }
      };

      fetchTimeData();

      const unsubscribe = onSnapshot(subscriptionDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const newTimeBank = docSnap.data().timeBank || 0;
          const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
          if (!sessionTimeBank || parseInt(sessionTimeBank, 10) !== newTimeBank) {
            setTimeBank(newTimeBank);
            sessionStorage.setItem(`timeBank_${user.uid}`, newTimeBank.toString());
          }
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  // Initiate the Stripe checkout session
  const startCheckoutSession = async () => {
    // Prevent multiple clicks if already loading or in success state
    if (isLoading || isSuccess) return;

    setIsLoading(true);
    setStripeButtonText("Processing...");

    try {
      const functions = getFunctions();
      const processCheckout = httpsCallable(functions, 'updateStripeSubscription');

      // Retrieve gclid from localStorage
      const gclid = localStorage.getItem('gclid') || '';

      // Include gclid in the payload sent to the backend
      const response = await processCheckout({
        selectedMinutes: selectedMinutes,
        planType: selectedPlan,
        gclid: gclid, // Include gclid if it exists
      });

      // Assuming the backend returns an object with a 'success' property
      if (response.data !== undefined && response.data !== null) {
        setIsSuccess(true);
        setStripeButtonText("Credits Added 🎉");

        // After 5 seconds, reset the button to its initial state
        setTimeout(() => {
          setIsSuccess(false);
          setStripeButtonText("Purchase Credits");
        }, 5000);
      } else {
        // Instead of throwing an error, set the button text to "Retry"
        setStripeButtonText("Retry");
      }
    } catch (error) {
      // Handle errors from the backend function
      // console.error("Checkout Error:", error);
      setStripeButtonText("Retry");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle the button click with confirmation logic
  const handleCheckoutClick = () => {
    // Prevent action if loading or in success state
    if (isLoading || isSuccess) return;

    if (!isConfirming) {
      // Enter confirmation state
      setIsConfirming(true);
      setStripeButtonText("Confirm?");

      // Set a timeout to revert back to initial state after 5 seconds
      confirmTimeoutRef.current = setTimeout(() => {
        setIsConfirming(false);
        setStripeButtonText("Purchase Credits");
      }, 5000);
    } else {
      // User confirmed, proceed with checkout
      setIsConfirming(false);
      setStripeButtonText("Purchase Credits"); // Reset text before starting checkout
      clearTimeout(confirmTimeoutRef.current);
      startCheckoutSession();
    }
  };

  // Cleanup timeout when component unmounts or popup closes
  useEffect(() => {
    return () => {
      if (confirmTimeoutRef.current) {
        clearTimeout(confirmTimeoutRef.current);
      }
    };
  }, []);

  // Add useEffect to reset confirmation when selectedPlan changes
  useEffect(() => {
    if (isConfirming) {
      setIsConfirming(false);
      setStripeButtonText("Purchase Credits");
      if (confirmTimeoutRef.current) {
        clearTimeout(confirmTimeoutRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);

  // If the popup is not open, render nothing
  if (!isOpen) return null;

  // Get the calculated prices
  const { originalPrice, discountedPrice } = calculatePrice();

  // Get the current plan details
  const currentPlanDetails = pricingDetails[selectedPlan];

  // Determine button class based on selected plan and states
  const buttonClassName = `paiduser-proceed-btn ${
    selectedPlan === 'Base' ? 'base-plan' : 'bump-plan'
  } ${isConfirming ? 'confirming' : ''} ${isSuccess ? 'success' : ''}`;

  // Determine button opacity
  const buttonOpacity = isLoading ? 0.7 : 1.0;

  // Determine pointer events
  const buttonPointerEvents = isLoading || isSuccess ? 'none' : 'auto';

  return (
    <div className="overlay-planpopup" onClick={onClose}>
      <div className="plan-popup-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="plan-popup-container">
          <button className="close-btn" onClick={onClose} aria-label="Close Popup">X</button>
          <h2 className="popup-header">Want more credits?</h2>
          <p className="bplan-learn-more-text">
            Unsure which plan fits your needs?{' '}
            <span onClick={onClose}>
              <Link to="/pricing" className="bplan-learn-more-link">Learn more</Link>
            </span>
          </p>
          <div className="select-wrapper">
            <select
              className="plan-choice-select"
              onChange={(e) => setSelectedPlan(e.target.value)}
              value={selectedPlan}
              aria-label="Select Plan"
            >
              <option value="Base">Base</option>
              <option value="Bump">Bump</option>
              {/* Add more options here if more plans are available */}
            </select>
            {/* Discount Badge */}
            <span className="discount-badge">25% OFF</span>
          </div>
          {(selectedPlan === 'Base' || selectedPlan === 'Bump') && currentPlanDetails && (
            <div className="plan-info">
              <p className="monthly-text">
                Monthly <img
                  src={currentPlanDetails.logo}
                  alt={`${selectedPlan} Plan Logo`}
                  className="bumpup-logo"
                />
              </p>
              <p className="price-text">
                <span className="original-price">${originalPrice} USD</span>
                <span className="discounted-price">${discountedPrice} USD</span>
              </p>
              <p
                className="upload-minutes-text"
                style={{ color: currentPlanDetails.color }}
              >
                {selectedMinutes} upload minutes
              </p>
              <p className="expiry-info-text">Receive monthly upload time</p>
              <select
                className="minutes-select plan-select"
                onChange={handleMinutesChange}
                value={selectedMinutes}
                aria-label="Select Minutes"
              >
                <option key={100} value={100}>100</option>
                <option key={200} value={200}>200</option>
                <option key={400} value={400}>400</option>
                <option key={600} value={600}>600</option>
                <option key={800} value={800}>800</option>
                <option key={1000} value={1000}>1000</option>
              </select>
            </div>
          )}
          <div className="additional-info">
            <p className="rebuy-info">
              Rebuy/Upgrade initiates a <strong>new plan at full cost</strong> and new upload time will be <strong>available instantly</strong>.
            </p>
            <p className="current-credits-info">
              Your current {timeBank ? formatTime(timeBank) : 'N/A'} of upload time will extend to the end of your new monthly billing period.
            </p>
            <p className="new-plan-info">
              The new plan renews monthly at US${discountedPrice} and your previous plan will be discontinued.
            </p>
          </div>
          <button
            className={buttonClassName}
            onClick={handleCheckoutClick}
            style={{
              backgroundColor: undefined, // Let CSS handle background color
              opacity: buttonOpacity,
              pointerEvents: buttonPointerEvents,
            }}
            aria-disabled={isLoading || isSuccess}
          >
            {isSuccess ? "Credits Added 🎉" : (isConfirming ? "Confirm?" : stripeButtonText)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaidUserPlanPopUp;
