// src/components/PlanPopUp.js
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PlanPopUp.css';

// FIREBASE
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, auth } from '../firebase';
import { getFunctions, httpsCallable } from "firebase/functions";

// FUNCTIONS
import { loadStripe } from '@stripe/stripe-js';

// IMAGES
import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';

const formatTime = (minutes) => {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  return `${h}h ${m}m`;
};

// Initialize Stripe with your public key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

// Define pricing details for each plan
const pricingDetails = {
  Base: {
    originalPer100: 2.50,
    discountedPer100: 1.99,
    logo: basePlanLogo,
    color: '#ffbf00', // Color for Base plan
  },
  Bump: {
    originalPer100: 10.00,
    discountedPer100: 7.99,
    logo: bumpPlanLogo,
    color: '#4add80', // Color for Bump plan
  },
  // Add more plans here if needed
};

const PlanPopUp = ({ isOpen, onClose }) => {
  const [selectedPlan, setSelectedPlan] = useState('Base');
  const [selectedMinutes, setSelectedMinutes] = useState(100);
  const [timeBank, setTimeBank] = useState(null);
  const [stripeButtonText, setStripeButtonText] = useState("Proceed to Stripe");

  // Handle changes in the minutes selection
  const handleMinutesChange = (e) => {
    setSelectedMinutes(parseInt(e.target.value, 10));
  };

  // Calculate original and discounted prices based on the selected plan and minutes
  const calculatePrice = () => {
    const plan = pricingDetails[selectedPlan];
    if (!plan) {
      // Fallback in case the plan is not found
      return { originalPrice: '0.00', discountedPrice: '0.00' };
    }

    const { originalPer100, discountedPer100 } = plan;
    const totalOriginal = (originalPer100 * (selectedMinutes / 100)).toFixed(2);
    const totalDiscounted = (discountedPer100 * (selectedMinutes / 100)).toFixed(2);

    return { originalPrice: totalOriginal, discountedPrice: totalDiscounted };
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const subscriptionDocRef = doc(db, 'users', user.uid, 'subscriptionData', 'timeWarden');

      const fetchTimeData = async () => {
        const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
        if (sessionTimeBank !== null) {
          setTimeBank(parseInt(sessionTimeBank, 10));
        } else {
          const docSnap = await getDoc(subscriptionDocRef);
          if (docSnap.exists()) {
            const timeBankData = docSnap.data().timeBank || 0;
            setTimeBank(timeBankData);
            sessionStorage.setItem(`timeBank_${user.uid}`, timeBankData.toString());
          }
        }
      };

      fetchTimeData();

      const unsubscribe = onSnapshot(subscriptionDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const newTimeBank = docSnap.data().timeBank || 0;
          const sessionTimeBank = sessionStorage.getItem(`timeBank_${user.uid}`);
          if (!sessionTimeBank || parseInt(sessionTimeBank, 10) !== newTimeBank) {
            setTimeBank(newTimeBank);
            sessionStorage.setItem(`timeBank_${user.uid}`, newTimeBank.toString());
          }
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  // Initiate the Stripe checkout session
  const startCheckoutSession = async () => {
    setStripeButtonText("Loading...");
    try {
      const functions = getFunctions();
      const processCheckout = httpsCallable(functions, 'processCheckout');

      // Retrieve gclid from localStorage
      const gclid = localStorage.getItem('gclid') || '';

      // Include gclid in the payload sent to the backend
      const response = await processCheckout({
        selectedMinutes: selectedMinutes,
        planType: selectedPlan,
        gclid: gclid,
        purchaseType: 'subscription',
      });

      const { id } = response.data;
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: id });

      if (error) {
        // Handle Stripe redirection errors
        setStripeButtonText("Retry");
      } else {
        // Reset button text if redirection is successful
        setStripeButtonText("Proceed to Stripe");
      }
    } catch (error) {
      // Handle errors from the backend function
      setStripeButtonText("Retry");
    }
  };

  // If the popup is not open, render nothing
  if (!isOpen) return null;

  // Get the calculated prices
  const { originalPrice, discountedPrice } = calculatePrice();

  // Get the current plan details
  const currentPlan = pricingDetails[selectedPlan];

  return (
    <div className="overlay-planpopup" onClick={onClose}>
      <div className="plan-popup-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="plan-popup-container">
          <button className="close-btn" onClick={onClose}>X</button>
          <h2 className="popup-header">Running low on credits?</h2>
          <p className="bplan-learn-more-text">
            Unsure which plan fits your needs?{' '}
            <span onClick={onClose}>
              <Link to="/pricing" className="bplan-learn-more-link">Learn more</Link>
            </span>
          </p>
          <div className="select-wrapper">
            <select
              className="plan-choice-select"
              onChange={(e) => setSelectedPlan(e.target.value)}
              value={selectedPlan}
            >
              <option value="Base">Base</option>
              <option value="Bump">Bump</option>
              {/* Add more options here if more plans are available */}
            </select>
            {/* Discount Badge */}
            <span className="discount-badge">25% OFF</span>
          </div>
          {(selectedPlan === 'Base' || selectedPlan === 'Bump') && (
            <div className="plan-info">
              <p className="monthly-text">
                Monthly <img
                  src={currentPlan.logo}
                  alt={`${selectedPlan} Plan Logo`}
                  className="bumpup-logo"
                />
              </p>
              <p className="price-text">
                <span className="original-price">${originalPrice} USD</span>
                <span className="discounted-price">${discountedPrice} USD</span>
              </p>
              <p
                className="upload-minutes-text"
                style={{ color: currentPlan.color }}
              >
                {selectedMinutes} upload minutes
              </p>
              <p className="expiry-info-text">Receive monthly upload time</p>
              <select
                className="minutes-select plan-select"
                onChange={handleMinutesChange}
                value={selectedMinutes}
              >
                <option key={100} value={100}>100</option>
                <option key={200} value={200}>200</option>
                <option key={400} value={400}>400</option>
                <option key={600} value={600}>600</option>
                <option key={800} value={800}>800</option>
                <option key={1000} value={1000}>1000</option>
              </select>
            </div>
          )}
          <div className="additional-info">
            <p className="rebuy-info">Rebuy/Upgrade initiates a <strong>new plan at full cost</strong> and new upload time will be <strong>available instantly</strong>.</p>
            <p className="current-credits-info">Your current {timeBank ? formatTime(timeBank) : 'N/A'} of upload time will extend to the end of your new monthly billing period.</p>
            <p className="new-plan-info">The new plan renews monthly at US${discountedPrice} and your previous plan will be discontinued.</p>
          </div>
          <button className="proceed-btn" onClick={startCheckoutSession}>{stripeButtonText}</button>
        </div>
      </div>
    </div>
  );
};

export default PlanPopUp;
